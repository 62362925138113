// Internal useful constants.
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;

// Intervals of time related to Dates in Collection List Filters
export const TIME_INTERVALS_ENUM = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
} as const;

// Tenses of time related to Dates for Collection List Filters
export const FUTURE = 'FUTURE' as const;
export const PAST = 'PAST' as const;

export const TENSES_ENUM = {
  FUTURE,
  PAST,
} as const;

export const TENSES_TO_HUMAN_PHRASES_MAP = {
  FUTURE: 'in the future',
  PAST: 'in the past',
} as const;

export const CLASS_NAME_W_DYN_BIND_EMPTY = 'w-dyn-bind-empty';

export const CONDITION_INVISIBLE_CLASS = 'w-condition-invisible' as const;

export const NON_EXISTING_ITEM_ID = '000000000000000000000000' as const;

export const QUERY_FILTER_FOR_STATES = {
  ALL: 'ALL' as const,
  ANY: 'ANY' as const,
} as const;

// The maximum number of items a set field can contain.
export const SET_FIELD_MAX_ITEMS = 25;

export const COLLECTION_LIST_QUERY_MODES = {
  CURATED: 'curated' as const,
  DYNAMIC: 'dynamic' as const,
} as const;

export type CollectionListQueryMode =
  (typeof COLLECTION_LIST_QUERY_MODES)[keyof typeof COLLECTION_LIST_QUERY_MODES];

/**
 * Granularity in minutes used to schedule publishes. For example: 8:00, 8:05,
 * etc.
 */
export const SCHEDULED_PUBLISH_GRANULARITY_IN_MIN = 5;

/**
 * How far in the future an item can be scheduled for SIP.
 */
export const SCHEDULED_PUBLISH_LIMIT_IN_MS = 5 * YEAR;

/**
 * Grace period to execute a scheduled publish. Let's say something is scheduled
 * to publish at 8:10 and it's 8:20. Is it still ok to publish?
 */
export const SCHEDULED_PUBLISH_GRACE_PERIOD_IN_MS = 30 * MINUTE;

/* An enum of the types of collections in our app. This allows us to handle
 * cases based on collection type by using the function getCollectionType
 */
export const COLLECTION_TYPES = {
  CATEGORIES: 'CATEGORIES' as const,
  CMS_COLLECTIONS: 'CMS_COLLECTIONS' as const,
  PRODUCTS: 'PRODUCTS' as const,
  SKUS: 'SKUS' as const,
} as const;

export const MIN_COLLECTION_LIST_OFFSET = 0;

export const SHARED_ALLOWED_FIELD_TYPES = {
  innerHTML: {
    PlainText: 'innerText',
    HighlightedText: 'innerText',
    RichText: 'innerHTML',
    Number: 'innerText',
    Video: 'innerHTML',
    Option: 'innerText',
    Date: 'innerText',
    Phone: 'innerText',
    Email: 'innerText',
    CommercePrice: 'innerHTML',
    Link: 'innerText',
    ImageRef: false,
    FileRef: false,
    ItemRef: false,
    CommercePropValues: 'innerText',
  },
  'style.color': {
    Color: true,
  },
  'style.background-color': {
    Color: true,
  },
  'style.border-color': {
    Color: true,
  },
  'style.background-image': {
    ImageRef: true,
  },
  src: ['ImageRef'],
  alt: [
    'PlainText',
    'Option',
    'Number',
    'Date',
    'Phone',
    'Email',
    'Video',
    'Link',
  ],
  href: ['Phone', 'Email', 'Video', 'Link', 'FileRef'],
  id: ['PlainText'],
  for: ['PlainText'],
  value: ['Number', 'PlainText'],
  checked: ['Bool'],
  dataWHref: ['PlainText', 'FullSlug'],
};

export const DATETIME_FORMAT_OPTIONS: string[] = [
  'MMMM D, YYYY',
  'MMMM D, YYYY h:mm A',
  'MMMM D, YYYY H:mm',
  'MMM D, YYYY',
  'MMM D, YYYY h:mm A',
  'MMM D, YYYY H:mm',
  'dddd, MMMM D, YYYY',
  'M/D/YYYY',
  'M.D.YYYY',
  'D/M/YYYY',
  'D.M.YYYY',
  'M/D/YYYY h:mm A',
  'M/D/YYYY H:mm',
  'M.D.YYYY h:mm A',
  'M.D.YYYY H:mm',
  'D/M/YYYY h:mm A',
  'D/M/YYYY H:mm',
  'D.M.YYYY h:mm A',
  'D.M.YYYY H:mm',
  'M/D/YY',
  'M.D.YY',
  'D/M/YY',
  'D.M.YY',
  'M/D/YY h:mm a',
  'M/D/YY H:mm',
  'M.D.YY h:mm a',
  'M.D.YY H:mm',
  'D/M/YY h:mm a',
  'D/M/YY H:mm',
  'D.M.YY h:mm a',
  'D.M.YY H:mm',
  'YYYY-MM-DD',
  'YYYY-MM-DD h:mm a',
  'YYYY-MM-DD H:mm',
  'MMM D',
  'D MMM',
  'MMMM YYYY',
  'MMM YYYY',
  'MM/YYYY',
  'h:mm a',
  'H:mm',
  'D',
  'DD',
  'ddd',
  'dddd',
  'M',
  'MM',
  'MMM',
  'MMMM',
  'YY',
  'YYYY',
];

export const DATE_FORMAT_OPTIONS: string[] = DATETIME_FORMAT_OPTIONS.filter(
  (format) => !/[hHmaA]/.test(format)
);
